var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._m(0),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("form-company", {
                  attrs: {
                    submitType: _vm.submitType,
                    submitString: _vm.submitString,
                    company: _vm.company
                  }
                })
              ],
              1
            )
          ])
        ])
      ]),
      _vm._m(1),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: "対応会社",
                            labelCol: { span: 3 },
                            wrapperCol: { span: 21 }
                          }
                        },
                        [
                          _c(
                            "a-checkbox-group",
                            {
                              model: {
                                value: _vm.activeHandledCompanies,
                                callback: function($$v) {
                                  _vm.activeHandledCompanies = $$v
                                },
                                expression: "activeHandledCompanies"
                              }
                            },
                            [
                              _c(
                                "a-col",
                                [
                                  _c(
                                    "a-checkbox",
                                    { attrs: { value: _vm.zero } },
                                    [_vm._v("【全社対応可】")]
                                  )
                                ],
                                1
                              ),
                              _vm._l(_vm.handledCompanies, function(item) {
                                return _c(
                                  "a-col",
                                  { key: item.id },
                                  [
                                    _c(
                                      "a-checkbox",
                                      {
                                        key: item.id,
                                        attrs: { value: item.id }
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "text-center" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.btnLoading
                              },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v("\n                更新\n              ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._m(2),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c("div", { staticClass: "card-body" }, [
              _vm.loading
                ? _c(
                    "div",
                    { staticClass: "text-center" },
                    [_c("a-spin", { attrs: { tip: "Loading..." } })],
                    1
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "div",
                    [
                      _c("a-table", {
                        attrs: {
                          columns: _vm.columns,
                          "data-source": _vm.apiUsage,
                          pagination: true,
                          rowKey: "month"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "month",
                              fn: function(id, record) {
                                return _c("span", {}, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm._f("moment")(record.month)) +
                                      "\n              "
                                  )
                                ])
                              }
                            },
                            {
                              key: "driving_time",
                              fn: function(id, record) {
                                return _c("span", {}, [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        parseInt(record.driving_time / 3600)
                                      ) +
                                      ":" +
                                      _vm._s(
                                        parseInt(
                                          (record.driving_time % 3600) / 60
                                        )
                                      ) +
                                      ":" +
                                      _vm._s(
                                        parseInt(record.driving_time % 60)
                                      ) +
                                      " (" +
                                      _vm._s(record.driving_time) +
                                      "行)\n              "
                                  )
                                ])
                              }
                            }
                          ],
                          null,
                          false,
                          3118302150
                        )
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _c("a-modal", {
        attrs: {
          title: "会社複製",
          visible: _vm.visibleDuplication,
          "confirm-loading": _vm.confirmLoading,
          okText: "複製",
          okType: "danger",
          cancelText: "キャンセル"
        },
        on: { ok: _vm.handleOkDuplication, cancel: _vm.handleCancelDuplication }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("会社編集")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("点呼代行対応会社")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("API使用量")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }