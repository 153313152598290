<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">会社編集</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-company
              :submitType="submitType"
              :submitString="submitString"
              :company="company"
            />
            <!-- <hr>
            <div>
              <p class="text-right"><a-button type="danger" @click="duplicationOpen">複製する</a-button></p>
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">点呼代行対応会社</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <a-form-model-item label="対応会社" :labelCol="{span: 3}" :wrapperCol="{span: 21}">
                <a-checkbox-group
                  v-model="activeHandledCompanies"
                >
                  <a-col>
                    <a-checkbox :value="zero">【全社対応可】</a-checkbox>
                  </a-col>
                  <a-col v-for="item in handledCompanies" :key="item.id">
                    <a-checkbox :key="item.id" :value="item.id">{{ item.name }}</a-checkbox>
                  </a-col>
                </a-checkbox-group>
              </a-form-model-item>
              <div class="text-center">
                <a-button type="primary" @click="onSubmit" :loading="btnLoading">
                  更新
                </a-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">API使用量</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!loading">
              <a-table
                :columns="columns"
                :data-source="apiUsage"
                :pagination="true"
                rowKey="month"
              >
                <span slot="month" slot-scope="id, record">
                  {{ record.month | moment }}
                </span>
                <span slot="driving_time" slot-scope="id, record">
                  {{ parseInt(record.driving_time / 3600) }}:{{ parseInt((record.driving_time % 3600) / 60) }}:{{ parseInt(record.driving_time % 60) }} ({{record.driving_time}}行)
                </span>
              </a-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      title="会社複製"
      :visible="visibleDuplication"
      :confirm-loading="confirmLoading"
      okText='複製'
      okType='danger'
      cancelText='キャンセル'
      @ok="handleOkDuplication"
      @cancel="handleCancelDuplication"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import moment from 'moment'
import FormCompany from '../../../components/FormComponents/Company'
import { objectData } from '@/services/object'

export default {
  components: {
    FormCompany,
  },
  data() {
    return {
      zero: 0,
      company: Object.assign({}, objectData.company),
      submitType: 'put',
      submitString: '更新',
      loading: true,
      apiUsage: [],
      visibleDuplication: false,
      confirmLoading: false,
      columns: [
        {
          title: '月',
          dataIndex: 'month',
          scopedSlots: { customRender: 'month' },
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.month > b.month,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '運転データ件数',
          dataIndex: 'drive_count',
          sorter: (a, b) => a.drive_count - b.drive_count,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '運転時間',
          dataIndex: 'driving_time',
          scopedSlots: { customRender: 'driving_time' },
          sorter: (a, b) => a.driving_time - b.driving_time,
          sortDirections: ['descend', 'ascend'],
        },
      ],

      handleRollCallAgentCompanyloading: true,
      handledCompanies: null,
      activeHandledCompanies: [],
      btnLoading: false,
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM')
    },
  },
  created() {
    const _this = this
    const result = Vue.prototype.$api.send('get', 'api_usage/' + _this.$route.params.id)
    result.then(response => {
      console.log('apiUsage', response)
      _this.apiUsage = response
      this.loading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': API利用量の取得に失敗しました。',
        })
        this.loading = false
      })

    const myCompanyBeHandledResult = Vue.prototype.$api.send('get', 'master/companies/' + _this.$route.params.id + '/handled_companies')
    myCompanyBeHandledResult.then(response => {
      _this.activeHandledCompanies = response.map(h => { return Number(h.handle_company_id) })
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': この会社の点呼代行受付会社の取得に失敗しました。',
        })
        this.loading = false
      })

    const handleCompanyResult = Vue.prototype.$api.send('get', 'master/handled_companies')
    handleCompanyResult.then(response => {
      _this.handledCompanies = response
      this.handleRollCallAgentCompanyloading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 点呼代行受付会社一覧の取得に失敗しました。',
        })
        this.loading = false
      })
  },
  methods: {
    duplicationOpen() { this.visibleDuplication = true },
    handleCancelDuplication() { this.visibleDuplication = false },
    handleOkDuplication() {
      this.confirmLoading = true
      const _this = this
      const postData = { company_id: _this.$route.params.id }
      const result = Vue.prototype.$api.send('post', 'company_duplication', postData)
      result.then(response => {
        console.log('duplicate', response)
        _this.$notification['success']({
          message: '複製しました。',
        })
        this.confirmLoading = false
        this.visibleDuplication = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
          })
          this.confirmLoading = false
        })
    },
    onSubmit() {
      const _this = this
      this.btnLoading = true
      const bodyData = {
        company_id: _this.$route.params.id,
        handled_companies: _this.activeHandledCompanies,
      }
      const result = Vue.prototype.$api.send('put', 'master/handled_companies', bodyData)
      result.then(response => {
        _this.$notification['success']({
          message: '更新しました。',
        })
        this.btnLoading = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
          })
          this.btnLoading = false
        })
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
